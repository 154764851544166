function FieldLookupDatasource(field, options) {
  var opts = $.extend({}, WEBADMIT.FieldLookupDatasource.defaults, options);
  var cacheKey = `field_${field.id}`;
  var engineOptions = {
    initialize: false,
    limit: opts.limit,
    datumTokenizer: function(datum) {
      return datum.name
        .replace(/\s-([a-zA-Z0-9_-]+)/g, ' - -$1 $1')
        .replace(/\((\w+)/, '( ($1 $1')
        .replace(/\[(\w+)/, '[ [$1 $1')
        .split(/\s+/);
    },
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    identify: function (obj) {
      return obj.value;
    }
  };

  if (WEBADMIT.FieldLookupDatasource.cache[cacheKey]) {
    engineOptions.local = WEBADMIT.FieldLookupDatasource.cache[cacheKey];
  } else if (WEBADMIT.FieldLookupDatasource.requests[cacheKey]) {
    engineOptions.prefetch = {
      url: '/dummy-url', // Dummy URL since actual fetch is handled manually
      transport: function (options, onSuccess, onError) {
        WEBADMIT.FieldLookupDatasource.requests[cacheKey].then((data) => {
            onSuccess(data);
        });
      }
    };
  } else {
    let resolveFn, rejectFn;
    WEBADMIT.FieldLookupDatasource.requests[cacheKey] = new Promise((resolve, reject) => {
      resolveFn = resolve;
      rejectFn = reject;
    });

    engineOptions.prefetch = {
      cache: opts.prefetch.cache,
      url: `/fields/${field.id}?for_clause_value_list=true`,
      transform: function (response) {
        WEBADMIT.FieldLookupDatasource.cache[cacheKey] = response.field_values;
        resolveFn(response.field_values);
        return response.field_values;
      }
    };
  }

  Bloodhound.call(this, engineOptions);
}

// WEBADMIT.FieldLookupDatasource extends Bloodhound
$(document).on('bloodhound-loaded', function () {
  WEBADMIT.FieldLookupDatasource = FieldLookupDatasource;
  WEBADMIT.FieldLookupDatasource.prototype = Object.create(Bloodhound.prototype);
  WEBADMIT.FieldLookupDatasource.prototype.constructor = FieldLookupDatasource;
  WEBADMIT.FieldLookupDatasource.defaults = {
    limit: 1000,
    prefetch: {
      cache: false
    }
  };

  WEBADMIT.FieldLookupDatasource.cache = {};
  WEBADMIT.FieldLookupDatasource.requests = {};
});
