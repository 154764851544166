document.addEventListener('click', function(event) {
    const link = event.target.closest('.pagy-async a');
    if (link) {
        event.preventDefault();

        const url = link.getAttribute('href');
        const targetDiv = event.target.closest('.async-content');
        const overlay = document.createElement('div');
        overlay.classList.add('loading-overlay');
        targetDiv.appendChild(overlay);

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'text/html',
            },
        })
        .then(function(response) {
            if (response.ok) {
                return response.text();
            } else {
                console.error('Error fetching the URL:', response.statusText);
            }
        })
        .then(function(html) {
            if (html) {
                targetDiv.innerHTML = html;
            }
        })
        .catch(function(error) {
            console.error('Error during AJAX request:', error);
        });
    }
});
